import { useEffect } from "react";
import { useLoaderData, useLocation } from '@remix-run/react';

interface LoaderData {
  chartbeatUser: number;
  chartbeatDomain: string;
}

const useChartbeat = ({ section, title }: { section: string, title: string }) => {
  const location = useLocation();
  const { chartbeatUser, chartbeatDomain } = useLoaderData() as LoaderData
  
  useEffect(() => {
    const existingScript = document.querySelector('script[src="//static.chartbeat.com/js/chartbeat.js"]');

    if (!existingScript) {
      // @ts-expect-error It has this key from chartbeat file
      const _sf_async_config = window._sf_async_config = (window._sf_async_config || {});

      // @ts-expect-error Has to be int
      _sf_async_config.uid = parseInt(chartbeatUser);
      _sf_async_config.domain = chartbeatDomain;
      _sf_async_config.flickerControl = false;
      _sf_async_config.useCanonical = true;
      _sf_async_config.useCanonicalDomain = true;
      _sf_async_config.title = title; // CHANGE THIS TO YOUR TITLE NAME
      _sf_async_config.sections = section; // CHANGE THIS TO YOUR SECTION NAME(s)
      _sf_async_config.authors = ''; // CHANGE THIS TO YOUR AUTHOR NAME(s)

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = '//static.chartbeat.com/js/chartbeat.js';
      document.head.appendChild(script);

      // @ts-expect-error It has this key from chartbeat file
    } else if (typeof window.pSUPERFLY !== 'undefined') {
      // @ts-expect-error It has this key from chartbeat file
      window.pSUPERFLY.virtualPage({
        sections: section,
        title: title
      });
    }
  }, [location.pathname, section, title, chartbeatUser, chartbeatDomain]);
};

export default useChartbeat;